<template>
  <v-container>
    <v-form ref="promotionCoupon-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn @click.prevent="$router.replace({ name: 'coupon' })" plain :disabled="isFetching">
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            <div>上一頁</div>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle>
              <div class="page-title">{{ $route.meta.title }}</div>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="string"
                    v-model="couponCode"
                    label="優惠碼（如不填寫，系統會自動產生一個優惠碼）"
                    :disabled="isFetching || $validate.DataValid(id)"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="status"
                    :options="statusList"
                    label="狀態"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="discountType"
                    :options="couponTypeList"
                    label="優惠類型"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="number"
                    v-model="discountValue"
                    :inputNumberOnly="discountType === 'percentage'"
                    :validateNumber="discountType === 'percentage'"
                    :inputFloatingOnly="discountType === 'fix'"
                    :validateFloating="discountType === 'fix'"
                    :label="couponDiscountTitle"
                    :required="true"
                    :allowEmpty="false"
                    :disabled="isFetching || !$validate.DataValid(discountType)"
                    :hint="couponDiscountHint"
                    :customChecking="[
                      v => {
                        if ($validate.DataValid(v)) {
                          if (discountType === 'percentage') {
                            const value = parseInt(v)

                            return (value > 0 && value <= 100) || '優惠不可少於0和大於100'
                          } else if (discountType === 'fix') {
                            return parseFloat(discountValue) > 0 || '優惠不可少於0'
                          }
                        }

                        return true
                      },
                    ]"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="reusable"
                    :options="repeatList"
                    label="一次性/可重用"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="dateRangePicker"
                    v-model="availableDates"
                    label="使用期限"
                    :required="availableDates.length > 0"
                    :disabled="isFetching"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" class="px-6" small depressed :disabled="isFetching" type="submit">儲存</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
 
 <script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'

export default {
  name: 'CouponDetail',
  components: {
    FormControl,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      status: '',
      discountType: '',
      discountValue: '',
      reusable: '',
      availableDates: [],
      couponCode: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function () {
      return this.$route.params.id
    },

    statusList: function () {
      return this.$statusList
    },

    couponTypeList: function () {
      return this.$couponTypeList
    },

    repeatList: function () {
      return [
        { text: '一次性', value: false },
        { text: '可重用', value: true },
      ]
    },

    couponDiscountTitle: function () {
      if (this.discountType === 'fix') {
        return '價格優惠'
      } else if (this.discountType === 'percentage') {
        return '百份比優惠'
      }

      return '優惠'
    },

    couponDiscountHint: function () {
      if (this.$validate.DataValid(this.discountValue)) {
        if (this.discountType === 'fix') {
          return `減$${this.discountValue}`
        } else if (this.discountType === 'percentage') {
          return `${(parseFloat(this.discountValue) / 10).toFixed(1)}折`
        }
      }

      return ''
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCouponDetail() {
      let couponError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_coupon_by_id', payload)
        this.$func.log('-----Get Coupon Detail-----')
        this.$func.log(result)

        this.status = result.status
        this.discountType = result.type
        this.discountValue = result.value
        this.reusable = result.reuseable

        if (this.$validate.DataValid(result.start_date) && this.$validate.DataValid(result.end_date)) {
          this.availableDates = [result.start_date, result.end_date]
        }

        this.couponCode = result.coupon_code

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Coupon Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          couponError = true
          message = '沒有權限'
        } else {
          couponError = true
        }
      } finally {
        this.setFetching(false)
      }

      if (couponError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'coupon',
        })
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        const isValid = this.$refs['promotionCoupon-form'].validate()
        if (!isValid) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            data_type: 'coupon_data',
            data_status: 'active',
            status: this.status,
            coupon_code: this.couponCode.trim(),
            type: this.discountType,
            value: this.discountValue.toString(),
            start_date: '',
            end_date: '',
            reuseable: this.reusable,
          }

          if (this.availableDates.length > 0) {
            if (this.availableDates.length === 1) {
              payload.start_date = this.availableDates[0]
              payload.end_date = this.availableDates[0]
            } else if (this.availableDates.length === 2) {
              payload.start_date = this.availableDates[0]
              payload.end_date = this.availableDates[1]
            }
          }

          let key = ''
          if (this.$validate.DataValid(this.id)) {
            payload.id = parseInt(this.id)
            key = 'cms_update_coupon'
          } else {
            key = 'cms_add_coupon'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('-----Update Coupon-----')
          this.$func.log(result)

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'coupon' },
          })

          this.setShowDialog(true)
          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----Update Coupon Fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'value must be an number') {
            message = '優惠只可輸入數字'
          } else if (error.data === 'invalid coupon value') {
            message = '優惠不正確'
          } else if (
            error.data === 'please provide both start and end date' ||
            error.data === 'start date required' ||
            error.data === 'end date required'
          ) {
            message = '請提供優惠開始和完結期限'
          } else if (error.data === 'start date cannot exceed end date') {
            message = '優惠開始日期不可早於完結期限'
          } else if (error.data === 'repeat coupon code') {
            message = '優惠碼已被使用'
          } else if (error.data === 'coupon code generation fail') {
            message = '優惠碼製作失敗'
          } else if (error.data === 'coupon not found') {
            message = '找不到優惠碼'
          }

          this.setFetching(false)
          this.submitSuccess = false
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getCouponDetail()
      }
    }
  },
}
</script>
 